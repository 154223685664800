
import {
    apiSettingsExpressTemuRegionEdit,
    apiSettingsExpressTemuRegionAdd,
} from '@/api/setting'
import { apiModelModelApplyDetail, apiModelModelApplyGetStatus, apiModelModelApplyGetLogList, apiModelModelApplyAddLog } from '@/api/model'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PageMode } from '@/utils/type'
import elImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
import Editor from "@/components/editor.vue";

@Component({
    components: {
        elImageViewer,
        Editor
    }
})
export default class LogisticsEdit extends Vue {
    
    $refs!: {
        formRef: any
    }

    form: any = {
        category: '',
        create_time: '',
        id: '',
        images: [],
        spec: [],
        status: -1,
        status_ch: ''
    }

    formData: any = []
    engineList: any = []
    temuEngineList: any = []
    type = ''
    mode = 'add'

    statusList: any = []
    psd_images: any = []
    face_images: any = []
    db_images: any = []
    produce_images: any = []

    logList = []
    formImages = []
    imgViewerVisible = false

    handleData: any = {
        content: '',
        images: [],
        status: 1
    }
    progressData: any = []
    uploading = false
    client: any = null
    imgList: any = []

    setContent(res:string){
        const regex = new RegExp('<img', 'gi')
        return res.replace(regex, `<img style="max-width: 100%; height: auto"`)
    }

    doHandle() {
        this.$refs.formRef.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            apiModelModelApplyAddLog({
                ...this.handleData,
                images: this.handleData.images.map((v: any) => v.url).join(','),
                id: this.form.id
            }).then(res=>{
                this.handleData.content = '';
                this.getLog();
            })
        })
    }

    dialogColse() {
        this.progressData = []
    }

    handlePreview(e: any) {
        const pickerObj: any = this.handleData.images.filter((v:any) => e.name === v.name)[0];
        this.imgList = [this.$store.getters.config.oss_domain + pickerObj.url];
        console.log(pickerObj)
        this.imgViewerVisible = true;
    }

    async handleUpload(file: any) {
        // await apiShopFacImagesAdd({ auth: 1 })
        this.uploading = true
        this.progressData.push({
            percentage: 0,
            name: file.file.name
        })
        this.multipartUpload(
            file.file,
            `/uploads/no_need_design/${file.file.name}`,
            this.progressData.length - 1
        )
    }
    async multipartUpload(file: any, filename: any, index: any) {
        await this.client.multipartUpload(filename, file, {
            progress: (percentage: any = 0, checkpoint: any) => {
                console.log('percentage', percentage)
                if (percentage) this.progressData[index].percentage = Math.round(percentage * 100)
                if (percentage == 1) {
                    this.handleData.images.push({
                        name: file.name,
                        url: filename
                    })
                    const count = this.progressData.filter((v: any) => v.percentage < 100).length;
                    if(count === 0)  {
                        this.uploading = false; this.progressData = [];
                    }
                    // this.uploading = false;
                }
            }
        })
    }
    handleRemove(e: any) {
        const index = this.handleData.images.findIndex((v:any) => {
            if(e.raw?.name) {
                return v.name === e.raw.name
            } else {
                return v.name === e.name
            }
        });
        this.handleData.images.splice(index, 1);
    }

    handleError() {}

    downLoad(item: any = []) {
        const domain = 'https://fqsaas.oss-cn-shanghai.aliyuncs.com'
        let url = item.map((v: any) => {
            return v.url
        })
        url.forEach((v: any) => {
            window.open(domain + v, '_blank')
        })
    }

    handleSave() {
        let params: any = {}
        if (this.mode == 'edit') {
            apiSettingsExpressTemuRegionEdit(this.form).then(res => {
                this.$message.success('编辑成功')
            })
        } else {
            apiSettingsExpressTemuRegionAdd(this.form).then(res => {
                this.$message.success('新增成功')
            })
        }
    }

    // 获取状态
    getStatus() {
        apiModelModelApplyGetStatus({})
            .then((res: any) => {
                this.statusList = res
                this.getDetail()
            })
            .catch(() => {})
    }

    //获取详情
    getDetail() {
        apiModelModelApplyDetail({ id: this.form.id }).then(res => {
            if (res.title) this.form.title = res.title
            if (res.category) this.form.category = res.category
            if (res.create_time) this.form.create_time = res.create_time
            if (res.id) this.form.id = res.id
            if (res.images) {
                this.form.images = res.images
                this.psd_images = res.images.filter((v: any) => v.type === 'psd_images')[0].images
                this.face_images = res.images.filter((v: any) => v.type === 'face_images')[0].images
                this.produce_images = res.images.filter((v: any) => v.type === 'produce_images')[0].images
                this.db_images = res.images.filter((v: any) => v.type === 'db_images')[0].images
            }
            if (res.spec) this.form.spec = res.spec
            if (res.hasOwnProperty('status')) {
                this.form.status = res.status;
                this.form.status_ch = this.statusList.filter((v: any) => v.key === res.status)[0].value
            }
        })
    }

    getLog() {
        apiModelModelApplyGetLogList({
            id: this.form.id,
            page: 1,
            page_size: 999
        }).then(res=>{
            res[0].color = '#0bbd87';
            this.logList = res;
        })
    }

    async created() {
        const query: any = this.$route.query
        if (query.mode) this.mode = query.mode

        if (this.mode === PageMode.EDIT) {
            this.form.id = query.id * 1
            this.getStatus()
            this.getLog()
        }
    }
    mounted() {
        let OSS = require('ali-oss')
        // 初始化OSS客户端
        const ossConfig = {
            accessKeyId: 'LTAI4FyKmzTLUN7G8LuirXnB',
            accessKeySecret: 'I2eSRxPcRFVzEFKaYgaBSnD1dON7Lv',
            bucket: 'fqsaas',
            endpoint: 'oss-cn-shanghai.aliyuncs.com',
            region: 'oss-cn-shanghai',
            domain: 'https://img.shopape.cn',
            ossdomain: 'https://fqsaas.oss-cn-shanghai.aliyuncs.com'
        }
        this.client = new OSS(ossConfig)
    }
}
